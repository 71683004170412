<template>
  <div>
    <div class="relative lg:flex hidden">
      <img :src="services" class="absolute top-52 right-0 w-96" />
    </div>
    <section class="py-24 px-4 lg:px-40 bg-dunkelblau border-b-2 border-white">
      <div
        class="flex flex-col lg:flex-row w-full justify-between items-center"
      >
        <div class="flex flex-col lg:w-3/5 lg:mt-0">
          <p class="text-3xl text-white font-semibold leading-loose">
            Analyse. Strategie. Umsetzung. Erfolg.
          </p>
          <p class="text-lg text-white mt-8 leading-loose">
            Wir denken und handeln nach digitalen Maßstäben. Wir bieten einen
            strukturierten Maßnahmenkatalog für Ihre digitale Transformation aus
            den folgenden Bereichen an:
          </p>
          <ul class="text-lg text-white leading-loose ml-10 mt-8 list-disc">
            <li>Prozess-Digitalisierung / Data Management</li>
            <li>Software-Entwicklung</li>

            <li>Online-Marketing</li>

            <li>Consulting</li>
            <li>Internet of Things</li>
            <li>Geschäftsmodelle</li>
            <li>KI-Nutzung</li>
            <li>Business Intelligence</li>
          </ul>
          <p class="text-lg text-white mt-8 leading-loose">
            Gemeinsam mit Ihnen analysieren wir Ihre digitale Aufgabenstellung,
            egal ob einzelne oder komplexe Bereiche im B2B oder B2C Umfeld. Wir
            erarbeiten Ihren Funktionsumfang und passen Ihre Anforderungen und
            Software an in Bezug auf Optimierung, Skalierbarkeit, Sicherheit,
            Design, Bedienbarkeit und der Option auf zukünftige Add-ons und
            Schnittstellen.
          </p>

          <p class="text-lg text-white mt-8 leading-loose">
            Nach der Zieldefinition folgen konkrete Maßnahmen, die durch
            strukturiertes Projektmanagement, definierte Timelines und
            Sprint-Planungen effektiv umgesetzt werden.
          </p>

          <p class="text-lg text-white mt-8 leading-loose">
            Um eine Nachhaltigkeit bei Digitalisierung Ihrer Prozesse zu
            gewährleisten, arbeiten wir u. a. mit aktuellen sowie etablierten
            technischen Möglichkeiten in der Softwareentwicklung.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import services from "@/assets/Leistungen_01.png";

export default {
  name: "Section1",
  setup() {
    return {
      services,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
