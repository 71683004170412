<template>
  <section class="py-20 px-4 lg:px-40 bg-hellblau border-b-2 border-white">
    <div
      class="flex flex-col  lg:flex-row w-full justify-between  items-center "
    >
      <div class="flex flex-col   w-full  lg:mt-0    ">
        <p class="text-3xl  text-anthrazit  font-semibold leading-loose  ">
          Prozess-Digitalisierung / Data Management
        </p>

        <p class="text-lg    mt-8  leading-loose  ">
          Daten sind die Basis, um bestmögliche Entscheidungen für Ihr
          Unternehmen zu treffen. Um diese treffen zu können, benötigen Sie alle
          relevanten Informationen in hoher Datenqualität.
        </p>

        <p class="text-lg   mt-8 leading-loose  ">
          Leider entstehen in vielen Unternehmen im Laufe der Zeit einige
          isolierte Datentanks mit unterschiedlichen Strukturen und ohne
          gegenseitige Vernetzung, so dass eine zielgerichtete Zusammenführung
          und Auswertung, entweder nur durch einen hohen Aufwand entsteht oder
          gar unmöglich ist.
        </p>

        <p class="text-lg    mt-8 leading-loose  ">
          Ziel von Data Management ist es, durch die Verknüpfungen von
          Datenpunkten eine wesentlich bessere Datenqualität herzustellen und
          daraus neue Zusammenhänge abzuleiten. Somit können Sie Entscheidungen
          auf einer fundierten Datengrundlage für eine künftige Ausrichtung
          Ihres Unternehmens treffen.
        </p>

        <p class="text-lg    mt-8 leading-loose  ">
          Wir sind Ihr professioneller Partner für Ihre Digitalisierungsprozesse
          und helfen Ihnen gerne dabei, Daten aus verschiedenen Quellen und
          Systemen in standardisierter und einheitlicher Qualität zu vereinen.
          Eine 360 Grad – Datensicht ist unser Ziel. Das senkt nachgelagerte
          Zeit- und Kostenaufwände Ihres Unternehmens.
        </p>
        <div class="flex  mt-10  justify-between">
          <div class="flex flex-col w-1/12 justify-center items-center">
            <img :src="betriebskosten" class="w-80"/>
            <p class="font-semibold text-anthrazit text-center mt-4">Weniger Betriebskosten</p>
          </div>
          <div class="flex flex-col w-1/12 justify-center items-center">
            <img :src="entlastung" class="w-80" />
           <p class="font-semibold text-anthrazit text-center mt-4">Größere Entlastung</p>
          </div>
          <div class="flex flex-col w-1/12 justify-center items-center">
            <img :src="zuverlaessigkeit" class="w-80" />
            <p class="font-semibold text-anthrazit text-center mt-4">Mehr Zuverlässigkeit</p>
          </div>
          <div class="flex flex-col w-1/12 justify-center items-center">
            <img :src="transparenz" class="w-80" />
            <p class="font-semibold text-anthrazit text-center mt-4">Mehr Transparenz</p>
          </div>
          <div class="flex flex-col w-1/12 justify-center items-center">
            <img :src="produktivitaet" class="w-80" />
           <p class="font-semibold text-anthrazit text-center mt-4">Mehr Produktivität</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import betriebskosten from "@/assets/Betriebskosten.svg";
import entlastung from "@/assets/Entlastung.svg";
import produktivitaet from "@/assets/Produktivitaet.svg";
import transparenz from "@/assets/Transparenz.svg";
import zuverlaessigkeit from "@/assets/Zuverlaessigkeit.svg";

export default {
  name: "Section2",
  setup() {
    return {
      betriebskosten,
      entlastung,
      produktivitaet,
      transparenz,
      zuverlaessigkeit,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
