<template>
  <section class="py-20 px-4 lg:px-40 bg-hellblau border-b-2 border-white">
    <div
      class="flex flex-col  lg:flex-row w-full justify-center  items-center "
    >
      <div class="flex flex-col  justify-center  w-full   lg:mt-0    ">
        <p class="text-3xl   self-center mb-8  font-semibold leading-loose  ">
          IoT
        </p>

        <p class="text-lg     leading-loose  ">
          Das Internet of Things verbindet in der Industrie oder beim Endkunden
          im Smart Home, Geräte, Sensoren und Software über das Internet und
          verschiedene Schnittstellen, um Daten auszutauschen, Prozesse zu
          automatisieren und standortunabhängig zu überwachen und zu steuern.
        </p>

        <p class="text-lg    mt-8  leading-loose  ">
          Mit Passion erstellen wir in diesem Segment eine perfekt auf Sie
          abgestimmte Synergie von unterschiedlichen Technologien und
          Anwendungen. Die dort verbundenen Prozesse bilden die Basis für eine
          neue Infrastruktur für die jeweiligen Geschäftsmodelle für B2B/B2C.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section5",
  setup() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
