<template>
  <div
    class=" bg-gray-800 overflow-hidden    flex flex-col md:flex-row  justify-center gap-x-16 items-center"
  >
    <div class="py-6 w-full md:max-w-xl px-4 flex flex-col justify-center ">
      <h2 class="text-white">
        <span class=" text-center">
          Erhöhe Deine Conversion-Rates und verbessere Dein Marketing-Ergebnis
          durch die Verwendung von KlickLeads innovativen
          Qualifizierungslösungen
        </span>
      </h2>
      <div class=" mt-4  inline-flex rounded-md  ">
        <a
          href="https://klicklead.de"
          target="_blank"
          class="py-2 px-3 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full md:w-auto transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
        >
          Jetzt kostenlos testen
        </a>
      </div>
    </div>
    <div class=" flex  py-6  md:py-0">
      <img :src="klickleadLogo" class="  h-full w-48 lg:block" />
    </div>
  </div>
</template>

<script>
import klickleadLogo from "@/assets/logo_klicklead-white.svg";

export default {
  name: "Klicklead",
  setup() {
    return {
      klickleadLogo,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
