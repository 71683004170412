<template>
  <section class=" ">
    <img :src="picture" class="w-full h-72 object-cover" />
  </section>
</template>

<script>
import picture from "@/assets/service.jpg";

export default {
  name: "Section8",
  setup() {
    return {
      picture,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
