<template>
  <section class="py-20 px-4 lg:px-40 bg-dunkelgruen border-b-2 border-white">
    <div class="flex justify-center my-8">
      <p
        class="text-3xl text-white self-center mb-8 font-semibold leading-loose"
      >
        Services
      </p>
    </div>
    <div class="flex flex-col lg:flex-row w-full justify-center items-center">
      <div
        class="flex flex-col md:flex-row justify-between gap-8 w-full lg:mt-0 text-white"
      >
        <div class="flex flex-col">
          <h2 class="text-lg font-bold">Online-Services</h2>
          <ul class="text-white leading-loose ml-10 mt-8 list-disc">
            <li>SEO & SEA</li>
            <li>Social Media Marketing</li>
            <li>E-Mail-Marketing</li>
            <li>Content-Marketing</li>
          </ul>
        </div>
        <div class="flex flex-col">
          <h2 class="text-lg font-bold">Marketing</h2>
          <ul class="text-white leading-loose ml-10 mt-8 list-disc">
            <li>Corporate Design / Logo Design</li>
            <li>UI / UX Design</li>
            <li>Multichannel</li>
            <li>Videoproduktion & -Erstellung</li>
            <li>Printprodukte</li>
            <li>Fotografie (People & Produkte)</li>
          </ul>
        </div>
        <div class="flex flex-col">
          <h2 class="text-lg font-bold">Software-Entwicklung</h2>
          <ul class="text-white leading-loose ml-10 mt-8 list-disc">
            <li>E-Commerce / Shopware</li>
            <li>KlickLead (Mobile Marketing-Funnels)</li>
            <li>Preis-Tool (Comparison-Management)</li>
            <li>Mobile App-Entwicklung</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section9",
  setup() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
