<template>
  <section class="py-20 px-4 lg:px-40 bg-dunkelgruen border-b-2 border-white">
    <div
      class="flex flex-col  lg:flex-row w-full justify-center  items-center "
    >
      <div class="flex flex-col  justify-center  w-full   lg:mt-0    ">
        <p class="text-3xl text-white self-center mb-8  font-semibold leading-loose  ">
          E-Commerce
        </p>

        <p class="text-lg text-white   leading-loose  ">
          Welches Shopsystem oder ERP-System ist für meine Anforderungen am
          besten geeignet? Welche E-Commerce Strategie und welcher Marketing-Mix
          ist für mich am effektivsten? Mit welchen Technologien und Frameworks
          steigere ich meine Onlineshop Performance? Und wie kann ich meine
          Prozesse optimieren und meine technischen Systeme miteinander
          verzahnen, um effizienter zu werden?
        </p>

        <p class="text-lg text-white  mt-8  leading-loose  ">
          Um im Onlinehandel erfolgreich zu sein, brauchen Sie neben einem
          performanten und stabilen Shopsystem eine praxisnahe digitale
          Strategie sowie ein zuverlässiges technisches Setup, das Ihre Prozesse
          und Systeme möglichst effizient miteinander vernetzt.
        </p>

        <p class="text-lg text-white mt-8 leading-loose  ">
          Sich im hochkomplexen Dschungel von E-Commerce Plattformen und
          Technologien, Schnittstellenintegrationen, Content Managementsystemen
          und Payment Solutions zurechtzufinden, ist nicht immer leicht. Deshalb
          halten wir Ihnen dabei den Rücken frei und machen modernen, agilen
          E-Commerce möglich.
        </p>
      </div>
    
    </div>
  </section>
</template>

<script>
export default {
  name: "Section3",
  setup() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
