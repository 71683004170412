<template>
  <div>
    <Hero />
    <Klicklead />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section8 />
    <Section9 />
    <Contact />
  </div>
</template>

<script>
import Hero from "@/components/Service/Hero";
import Section1 from "@/components/Service/Section1";
import Section2 from "@/components/Service/Section2";
import Section3 from "@/components/Service/Section3";
import Section4 from "@/components/Service/Section4";
import Section5 from "@/components/Service/Section5";
import Section8 from "@/components/Service/Section8";
import Section9 from "@/components/Service/Section9";

import Contact from "@/components/Home/Contact";
import Klicklead from "@/components/Service/Klicklead";

export default {
  name: "Service",
  components: {
    Hero,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section8,
    Section9,
    Contact,
    Klicklead,
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
